import React from "react";
import { graphql } from "gatsby";
import Seo from "../components/seo";
import Matrix from "../components/matrix";
import Layout from "../components/layout";
import SectionHead from "../components/newComponents/sectionHead";

export const query = graphql`
  query AreaOfExpertiseEntryQuery($slug: String!) {
    entry: craftAreaOfExpertiseAreaOfExpertiseEntry(slug: { eq: $slug }) {
      id
      remoteId
      title
      bodyContent {
        ...BodyContentFragment
      }
    }
  }
`;

const NewsEntry = ({ data: { entry } ,pageContext }) => {
  return (
    <Layout pageContext={pageContext}>
      <Seo title={entry.title} />      
      <div className="main">
        <section className="section-inside">
          <div className="shell">
            <SectionHead heading={entry.title} />
            <Matrix blocks={entry.bodyContent} />
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default NewsEntry;